<template>
  <div class="page nofound">
    <div class="pageHeader">
      <i class="iconfont icon-zuojiantou" @click="back"></i>
      <h1 class="pageTitle">找不到页面</h1>
      <p class="pageDesc">请再仔细检查地址</p>
    </div>
  </div>
</template>
<script setup>
import {useRouter} from 'vue-router'
const router = useRouter()
const back=()=>{
    router.go(-1)
}

</script>
